import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useMutation } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export const useUncertainBilling = (variables) => {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(UNCERTAIN_BILLING_QUERY, variables, () => ({
    enabled: !!variables.value.id,
  }));

  const uncertainBilling = computed(() => result?.value?.uncertainBillingById ?? {});
  const loading = useLoading(queryLoading, variables);
  onError((param) => {
    console.error('useUncertainBilling', param);
    error();
  });

  return {
    uncertainBilling,
    refetch,
    loading,
  };
};

const UNCERTAIN_BILLING_QUERY = gql`
  query uncertainBilling($id: ID!) {
    uncertainBillingById(id: $id) {
      totalAmount
      reason
      eventReferences {
        documentId
        reference
        regulatory
      }
    }
  }
`;

export const useResolveUncertainBilling = () => {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(RESOLVE_UNCERTAIN_BILLING);

  onError((err) => {
    console.error('useResolveUncertainBilling', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
};

const RESOLVE_UNCERTAIN_BILLING = gql`
  mutation uncertainBillingResolve($uncertainId: ID!, $billingId: ID) {
    uncertainBillingResolve(id: $uncertainId, billingId: $billingId) {
      id
    }
  }
`;
