<template>
  <div v-if="fieldDefinition.label" class="card mb-4">
    <div class="card-header">{{ fieldDefinition.label }}</div>
    <div class="card-body">
      <autoinput v-for="key in schema.objectKeys()" :key="key" :field-key="`${fieldKey}.${key}`" />
    </div>
  </div>
  <div v-else class="d-flex">
    <autoinput v-for="key in schema.objectKeys()" :key="key" :field-key="`${fieldKey}.${key}`" class="mx-1 mb-0" />
  </div>
</template>

<script type="text/javascript">
import Autoinput from './autoinput';

export default {
  components: { Autoinput },
  props: ['fieldKey', 'fieldDefinition', 'value', 'rule'],
  computed: {
    schema() {
      return this.fieldDefinition && this.fieldDefinition.type[0].type;
    },
  },
};
</script>
