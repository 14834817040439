import { gql } from '@apollo/client/core';
import { watch, ref } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useRequests = (variables) => {
  const { error } = useNotification();
  const { result, loading: fetching, refetch, onError } = useQuery(REQUESTS_QUERY, variables);
  const { mutate, onDone: onCreateDone, onError: onCreateError } = useMutation(REQUEST_CREATE_MUTATION);
  const { mutate: updateRequest, onDone: onUpdateDone, onError: onUpdateError } = useMutation(REQUEST_UPDATE_MUTATION);
  const {
    mutate: updateRequestResponse,
    onDone: onUpdateResponseDone,
    onError: onUpdateResponseError,
  } = useMutation(REQUEST_RESPONSE_UPDATE);

  const loading = ref(false);
  const requests = ref();
  const totalCount = ref(0);

  const requestsConnection = useResult(result, { nodes: [], totalCount: 0 });

  watch(
    requestsConnection,
    (requestsConnection) => {
      requests.value = requestsConnection.nodes;
      totalCount.value = requestsConnection.totalCount;
    },
    { immediate: true }
  );

  onError((err) => {
    console.error('useRequests', err);
    error();
  });
  onCreateError((err) => {
    console.error('useRequests.create', err);
    error();
  });
  onUpdateError((err) => {
    console.error('useRequests.update', err);
    error();
  });
  onUpdateResponseError((err) => {
    console.error('useRequests.updateRequestResponse', err);
    error();
  });

  onCreateDone(async () => {
    await refetch();
    loading.value = false;
  });
  onUpdateDone(async () => {
    await refetch();
    loading.value = false;
  });
  onUpdateResponseDone(async () => {
    await refetch();
    loading.value = false;
  });

  watch(
    [fetching],
    ([fetching]) => {
      loading.value = fetching;
    },
    { immediate: true }
  );

  const optimisticUpdateStatus = (args, sourceBusiness, targetBusiness, createdBy) => {
    totalCount.value += 1;

    const newRequest = {
      sourceBusinessId: args.createParams.sourceBusinessId,
      targetBusinessId: args.createParams.targetBusinessId,
      details: args.createParams.details,
      taskId: args.createParams.taskId,
      createdBy: { firstName: createdBy.profile.firstName, lastName: createdBy.profile.lastName },
      createdAt: new Date().getTime(),
      activeAt: new Date().getTime(),
      targetBusiness: { name: targetBusiness.name },
      sourceBusiness: { name: sourceBusiness.name },
    };
    requests.value = [...requests.value, newRequest];
  };

  const createRequest = async (args, sourceBusiness, targetBusiness, createdBy) => {
    loading.value = true;
    optimisticUpdateStatus(args, sourceBusiness, targetBusiness, createdBy);
    mutate(args);
  };

  const optimisticCloseRequest = (requestId, answer, closedBy) => {
    const temp = [...requests.value];
    const index = temp.findIndex(({ id }) => id === requestId);
    const request = { ...temp[index] };
    temp.splice(index, 1);

    request.closed = true;
    request.closedAt = new Date().getTime();
    request.answer = answer;
    request.closedBy = { firstName: closedBy.profile.firstName, lastName: closedBy.profile.lastName };
    requests.value = [...temp, request];
  };

  const closeRequest = (id, answer, closedBy) => {
    optimisticCloseRequest(id, answer, closedBy);
    updateRequest({ id, data: { closed: true, answer } });
  };

  const optimisticUpdateResponse = ({ requestId, index, updateParams }, user) => {
    const temp = [...requests.value];
    const requestIndex = temp.findIndex(({ id }) => id === requestId);
    const request = { ...temp[requestIndex] };

    const updatedResponses = [...request.responses];

    updatedResponses[index] = {
      ...updatedResponses[index],
      reject: {
        ...updateParams.reject,
        createdBy: { firstName: user.profile.firstName, lastName: user.profile.lastName },
        createdAt: new Date().getTime(),
      },
    };

    request.responses = updatedResponses;
    temp[requestIndex] = request;
    requests.value = [...temp];
  };

  const updateResponse = ({ requestId, index, updateParams }, user) => {
    optimisticUpdateResponse({ requestId, index, updateParams }, user);
    updateRequestResponse({ requestId, index, updateParams });
  };

  return {
    requests,
    totalCount,
    loading,
    refetch,
    onCreateDone,
    onCreateError,
    createRequest,
    closeRequest,
    updateResponse,
  };
};

export const REQUESTS_QUERY = gql`
  query requests($limit: Int, $offset: Int, $sourceBusinessId: String, $targetBusinessId: String, $taskId: String) {
    requests(
      limit: $limit
      offset: $offset
      sourceBusinessId: $sourceBusinessId
      targetBusinessId: $targetBusinessId
      taskId: $taskId
    ) {
      totalCount
      nodes {
        id
        sourceBusinessId
        sourceBusiness {
          id
          name
          number
        }
        targetBusinessId
        targetBusiness {
          id
          name
          number
        }
        taskId
        task {
          id
          type
        }
        details
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
        activeBy {
          id
          firstName
          lastName
        }
        activeAt
        comment
        closed
        closedAt
        closedBy {
          id
          firstName
          lastName
        }
        answer
        inquiries {
          id
          content
          timestamp
        }
        template
        templateData {
          balanceAlignment {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
          balanceAlignmentCheckNewInvoices {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
          balanceAlignmentMissingDocuments {
            documents {
              docNumber
              docType
              docDate
            }
          }
          balanceAlignmentReconciliationStatement {
            reconciliationPeriodStart
            reconciliationPeriodEnd
            reconciliationDate
          }
        }
        responses {
          attachments
          filePathUrls
          text
          reject {
            text
            createdBy {
              id
              firstName
              lastName
            }
            createdAt
          }
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
        }
      }
    }
  }
`;

export const REQUEST_CREATE_MUTATION = gql`
  mutation requestCreate($createParams: RequestCreateInput!) {
    requestCreate(createParams: $createParams) {
      id
    }
  }
`;

export const REQUEST_UPDATE_MUTATION = gql`
  mutation requestUpdate($id: ID!, $data: RequestUpdateInput) {
    requestUpdate(id: $id, data: $data) {
      id
    }
  }
`;

const REQUEST_RESPONSE_UPDATE = gql`
  mutation requestResponseUpdate($requestId: ID!, $index: Int!, $updateParams: ResponseUpdateInput!) {
    requestResponseUpdate(requestId: $requestId, index: $index, updateParams: $updateParams) {
      id
    }
  }
`;
