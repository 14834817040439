<template>
  <ul class="list-group">
    <li class="list-group-header">
      <el-button size="mini" icon="el-icon-plus" class="float-right" @click="addItem()" />
      {{ fieldDefinition && fieldDefinition.label }}
    </li>
    <li v-for="(item, index) in value" :key="arrayKeys[index]" class="list-group-item">
      <el-button type="text" icon="el-icon-delete" class="float-right" @click="removeItem(index)" />
      <autoinput :key="`${fieldKey}.${index}`" :field-key="`${fieldKey}.${index}`" />
    </li>
  </ul>
</template>

<script type="text/javascript">
import Autoinput from './autoinput';
import { getModel } from './autoform_utils';

export default {
  name: 'AutoformArray',
  components: { Autoinput },
  props: ['fieldKey', 'fieldDefinition', 'elemFieldDefinition', 'rule', 'value'],
  data() {
    const arrayKeys = [];
    if (this.value instanceof Array) {
      this.value.forEach(() => arrayKeys.push(Math.random()));
    }
    return {
      arrayKeys,
      controlledValueChange: false,
    };
  },
  watch: {
    value(newValue) {
      if (this.controlledValueChange) {
        this.controlledValueChange = false;
        return;
      }
      this.arrayKeys = newValue?.map(() => Math.random()) ?? [];
    },
  },
  methods: {
    addItem(key) {
      this.value.push(getModel(this.elemFieldDefinition.type[0].type));
      this.arrayKeys.push(key || Math.random());
      this.controlledValueChange = true;
    },
    removeItem(index) {
      this.value.splice(index, 1);
      this.arrayKeys.splice(index, 1);
      this.controlledValueChange = true;
    },
    addItemRowAbove(index) {
      const newItem = getModel(this.elemFieldDefinition.type[0].type);
      this.value.splice(index, 0, newItem);
      this.arrayKeys.splice(index, 0, Math.random());
      this.controlledValueChange = true;
    },
  },
};
</script>
