import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export const useBillingEventReferences = () => {
  const { error } = useNotification();
  const { mutate: updateEventReferences, onDone, onError } = useMutation(UPDATE_BILLING_EVENT_REFERENCE);

  onError((err) => {
    console.error('useBillingEventReferences', err);
    error();
  });

  return {
    updateEventReferences,
    onDone,
  };
};

const UPDATE_BILLING_EVENT_REFERENCE = gql`
  mutation billingEventReferenceUpdate($billingId: ID!, $index: Int!, $updateParams: EventReferenceUpdateInput!) {
    billingEventReferenceUpdate(billingId: $billingId, index: $index, updateParams: $updateParams) {
      id
    }
  }
`;
