<template>
  <el-form-item v-if="type === Boolean" :prop="fieldKey" :rules="rule">
    <el-checkbox v-model="model" border class="w-100 mb-0">{{ fieldDefinition && fieldDefinition.label }}</el-checkbox>
  </el-form-item>
  <el-form-item v-else-if="isNumber(type)" :prop="fieldKey" :rules="rule">
    <el-input
      v-model="model"
      type="number"
      :placeholder="fieldDefinition && fieldDefinition.label"
      class="w-100 direction"
    />
  </el-form-item>
  <el-form-item v-else-if="allowedValues" :prop="fieldKey" :rules="rule">
    <el-select v-model="model" clearable :placeholder="fieldDefinition && fieldDefinition.label" class="w-100">
      <el-option v-for="value in allowedValues" :key="value" :value="value" />
    </el-select>
  </el-form-item>
  <el-form-item v-else-if="type === Date" :prop="fieldKey" :rules="rule">
    <el-date-picker
      v-model="dateModel"
      value-format="yyyy-MM-dd"
      format="dd/MM/yyyy"
      :placeholder="fieldDefinition.label"
      class="w-100"
    />
  </el-form-item>
  <el-form-item v-else :prop="fieldKey" :rules="rule">
    <el-input v-model="model" :placeholder="fieldDefinition && fieldDefinition.label" class="w-100 direction" />
  </el-form-item>
</template>

<script type="text/javascript">
import SimpleSchema from 'simpl-schema';
import moment from 'moment';

export default {
  props: ['fieldKey', 'fieldDefinition', 'value', 'rule'],
  computed: {
    type() {
      return this.fieldDefinition && this.fieldDefinition.type[0].type;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dateModel: {
      get() {
        return this.model && moment.utc(this.model).format('YYYY-MM-DD');
      },
      set(value) {
        this.model = value && moment.utc(value, 'YYYY-MM-DD').toDate();
      },
    },
    allowedValues() {
      return this.fieldDefinition && this.fieldDefinition.type[0].allowedValues;
    },
  },
  methods: {
    isNumber(type) {
      return type === Number || type === SimpleSchema.Integer;
    },
  },
};
</script>

<style scoped>
.direction {
  direction: initial;
}
</style>
