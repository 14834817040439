import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useDocument(variables, options) {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(DOCUMENT_QUERY_NEW2_WITH_REPLICATES, variables, options);

  const document = computed(() => result.value?.documentNew2);

  onError((err) => {
    console.error('useDocument - with replicas', err);
    error();
  });

  return {
    document,
    loading,
    onError,
    refetch,
  };
}

export const DOCUMENT_QUERY_NEW2_WITH_REPLICATES = gql`
  query documentWithReplicas($id: ID!) {
    documentNew2(id: $id) {
      replicates {
        id
        recordedAt
        type
        documentNumber
        issueDate
        deliveryDate
        filePathUrl
      }
      id
      recordedAt
      type
      documentNumber
      issueDate
      deliveryDate
      filePathUrl
    }
  }
`;
