import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useTasks(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(TASKS_QUERY, variables, () => ({ enabled: !!variables.value.businessId }));

  const loading = useLoading(queryLoading, variables);

  const deliveryTasks = computed(() => result.value?.deliveryTasks?.nodes ?? []);
  const handleReconciliationTasks = computed(() => result.value?.handleReconciliationTasks?.nodes ?? []);
  const supplierCreationTasks = computed(() => result.value?.supplierCreationTasks?.nodes ?? []);
  const uncertainBillingTasks = computed(() => result.value?.uncertainBillingTasks?.nodes ?? []);
  const balanceAlignmentTasks = computed(() => result.value?.balanceAlignmentTasks?.nodes ?? []);

  onError((param) => {
    console.error('tasks - useTasks', param);
    error();
  });

  const { mutate: taskComplete } = useCloseTask();

  return {
    deliveryTasks,
    handleReconciliationTasks,
    supplierCreationTasks,
    uncertainBillingTasks,
    balanceAlignmentTasks,
    refetch,
    loading,
    taskComplete,
  };
}

export function useActivateTask() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_ACTIVE_MUTATION);

  onError((param) => {
    console.error('useActivateTask', param);
    error();
  });

  return {
    activeTask: mutate,
    loading,
    onDone,
    onError,
  };
}

const TASK_ACTIVE_MUTATION = gql`
  mutation taskActive($taskId: ID!, $activeParams: TaskActiveInput!) {
    taskActive(taskId: $taskId, activeParams: $activeParams) {
      id
      domain
      type
      data
      reviewRequired
    }
  }
`;

export function usePatchTask() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(TASK_PATCH_MUTATION);

  onError((param) => {
    console.error('usePatchTask', param);
    error();
  });

  return {
    patchTask: mutate,
    loading,
    onDone,
    onError,
  };
}

const TASK_PATCH_MUTATION = gql`
  mutation taskPatch($taskId: ID!, $patchParams: TaskPatchInput!) {
    taskPatch(taskId: $taskId, patchParams: $patchParams) {
      id
      domain
      type
      data
      reviewRequired
    }
  }
`;

const useCloseTask = () => {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(TASK_COMPLETE_MUTATION);

  onError((err) => {
    console.error('useCloseTask', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
};

const TASK_COMPLETE_MUTATION = gql`
  mutation taskComplete($taskId: ID) {
    taskComplete(taskId: $taskId) {
      id
      completedAt
      completedBy {
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

const TASKS_QUERY = gql`
  query tasks($businessId: ID!, $completed: Boolean) {
    handleReconciliationTasks: tasksNew2(
      businessId: $businessId
      domain: "reconciliation"
      type: "handleReconciliation"
      completed: $completed
    ) {
      totalCount
      nodes {
        id
        createdAt
        completedAt
        type
        activeAt
        activeBy: activeByNew {
          firstName: given_name
          lastName: family_name
        }
        completedBy: completedByNew {
          firstName: given_name
          lastName: family_name
        }
        data
      }
    }
    deliveryTasks: tasksNew2(businessId: $businessId, completed: false, domain: "delivery") {
      totalCount
      nodes {
        id
        completedAt
        delivery {
          document {
            id
            supplierId
          }
        }
      }
    }
    supplierCreationTasks: tasksNew2(
      businessId: $businessId
      domain: "restaurantSupplierRelations"
      type: "supplierCreation"
      completed: $completed
    ) {
      totalCount
      nodes {
        id
        createdAt
        completedAt
        type
        activeAt
        activeBy: activeByNew {
          firstName: given_name
          lastName: family_name
        }
        completedBy: completedByNew {
          firstName: given_name
          lastName: family_name
        }
        data
      }
    }
    uncertainBillingTasks: tasksNew2(
      businessId: $businessId
      domain: "billing"
      type: "uncertainBilling"
      completed: $completed
    ) {
      totalCount
      nodes {
        id
        createdAt
        completedAt
        type
        activeAt
        activeBy: activeByNew {
          firstName: given_name
          lastName: family_name
        }
        completedBy: completedByNew {
          firstName: given_name
          lastName: family_name
        }
        data
      }
    }
    balanceAlignmentTasks: tasksNew2(
      businessId: $businessId
      domain: "reconciliation"
      type: "balanceAlignment"
      completed: $completed
    ) {
      totalCount
      nodes {
        id
        createdAt
        completedAt
        type
        activeAt
        activeBy: activeByNew {
          firstName: given_name
          lastName: family_name
        }
        completedBy: completedByNew {
          firstName: given_name
          lastName: family_name
        }
        data
      }
    }
  }
`;

export const usePatchTaskDirect = () => {
  const { error: errorNotification } = useNotification();

  const { mutate, loading } = useMutation(TASK_PATCH_MUTATION);

  const patchTask = async (variables) => {
    try {
      return mutate(variables);
    } catch (error) {
      errorNotification(error);
      throw error;
    }
  };

  return {
    patchTask,
    loading,
  };
};
