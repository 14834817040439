import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client';

export const useBusinessById = (businessId) => {
  const { result, loading, onError, refetch } = useQuery(
    BUSINESS_BY_ID,
    () => ({ businessId: businessId.value }),
    () => ({
      enabled: !!(businessId && businessId?.value),
      fetchPolicy: 'cache-first',
    })
  );

  const business = computed(() => result.value?.getBusinessById);

  return {
    business,
    loading,
    refetch,
    onError,
  };
};

const BUSINESS_BY_ID = gql`
  query getAllocationBusinessById($businessId: ID!) {
    getBusinessById(businessId: $businessId) {
      id
      name
      type
    }
  }
`;
