import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useDelivery = (variables, options) => {
  const { error } = useNotification();
  const { result, loading, refetch, onError } = useQuery(DELIVERY_QUERY, variables, options);

  const delivery = computed(() => result.value?.delivery);

  onError((err) => {
    console.error('tasks - useDelivery', err);
    error();
  });

  return {
    delivery,
    loading,
    refetch,
  };
};

export const useDeliveries = (variables, options) => {
  const { error } = useNotification();
  const { result, loading, refetch, onError } = useQuery(DELIVERIES_QUERY, variables, options);

  const deliveries = computed(() => result?.value?.deliveries.nodes ?? []);

  onError((err) => {
    console.error('tasks - useDeliveries', err);
    error();
  });

  return {
    deliveries,
    loading,
    refetch,
  };
};

export const useDeliveryPatch = () => {
  const { error } = useNotification();
  const { mutate: patchDelivery, loading, onDone, onError } = useMutation(DELIVERY_PATCH_NEW_MUTATION);

  onError((err) => {
    console.error('tasks - useDeliveryPatch', err);
    error();
  });

  return {
    patchDelivery,
    loading,
    onDone,
  };
};

const DELIVERIES_QUERY = gql`
  query deliveriesQuery($businessId: ID, $supplierId: ID!, $date: ISODate!) {
    deliveries(businessId: $businessId, supplierId: $supplierId, fromDate: $date, toDate: $date) {
      nodes {
        id
        businessId
        supplierId
        date
        products {
          id
          productId
          quantity
          items {
            id
            productId
            quantity
          }
        }
        eventReferences {
          documentId
        }
      }
    }
  }
`;

const DELIVERY_QUERY = gql`
  query delivery($id: ID!) {
    delivery(id: $id) {
      id
      businessId
      supplierId
      date
      products {
        id
        productId
        quantity
        items {
          id
          productId
          quantity
        }
      }
      eventReferences {
        documentId
      }
    }
  }
`;

const DELIVERY_PATCH_NEW_MUTATION = gql`
  mutation deliveryPatch($id: ID!, $data: DeliveryPatchInput) {
    deliveryPatch(id: $id, data: $data) {
      id
    }
  }
`;
