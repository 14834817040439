import { computed, ref } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useLastReconciliation(args) {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(LAST_RECONCILIATION_QUERY, args, () => ({
    enabled: !!args.value.businessId && !!args.value.supplierId,
  }));

  const reconciliation = computed(() => {
    const reconciliation = result?.value?.reconciliations?.nodes.slice(-1)[0];
    if (!reconciliation) return [];
    return reconciliation;
  });

  const variables = ref({ args });
  const loading = useLoading(queryLoading, variables);
  onError((err) => {
    console.log('err: ', err);
    error();
  });

  return {
    reconciliation,
    refetch,
    loading,
  };
}

const LAST_RECONCILIATION_QUERY = gql`
  query getReconciliation($businessId: ID!, $supplierId: ID) {
    reconciliations(businessId: $businessId, supplierId: $supplierId, enrich: false) {
      nodes {
        id
        paymentDueDate
        supplierId
        businessId
        periodStart
        periodEnd
        balanceAlignment {
          required
          validated
          note
        }
        closed
      }
    }
  }
`;

export function useReconciliations(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(RECONCILIATIONS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.ids?.length,
  }));

  const reconciliations = computed(() => result?.value?.reconciliations.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('task useReconciliations', err);
    error();
  });

  return {
    reconciliations,
    loading,
    refetch,
  };
}

export const RECONCILIATIONS_QUERY = gql`
  query reconciliations($businessId: ID!, $ids: [String]) {
    reconciliations(businessId: $businessId, ids: $ids) {
      nodes {
        id
        businessId
        supplierId
        periodStart
        periodEnd
        paymentDueDate
        actionsPerformed
        status {
          status
          details {
            key
            status
            details {
              key
              status
            }
          }
        }
        statusOverride {
          status
          comment
          modifiedAt
          modifiedByDetails {
            firstName
            lastName
          }
        }
        statusReflection {
          reason
          updatedBy {
            firstName
            lastName
          }
          updatedAt
        }
        billings {
          id
        }
        balanceAlignment {
          required
          validated
          note
        }
        paidAmounts {
          paymentId
          billingId
          amount
        }
        billedAmounts {
          billingId
          amount
          date
        }
      }
    }
  }
`;
